






import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import FormBase from '@/shared/classes/form/form-base'
import Employee from '@/shared/modules/employee/employee.model'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import IEmployeeResponse from '@/shared/modules/employee/employee-response.interface'
import { HttpMethod } from '@/shared/helpers/requests.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class EmployeeChangeEmailDialog extends Vue {
  @Prop() meta!: any
  form: FormBase|null = null

  created() {
    this.form = new FormBase()
      .setMethod(HttpMethod.POST)
      .setEndpoint(`company/${ this.company.slug }/employees/${ this.meta.employee.uuid }/update-email`)
      .setEntry(this.meta.employee)
      .setModel(Employee)
      .setFields([
        new Field()
          .setType(FieldTypes.email)
          .setKey('email')
          .setTitle(__('company.components.employees.change-email.form.email'))
          .isRequired(),
      ])
      .setSubmit({
        text: __('company.components.employees.change-email.form.submit')
      })
      .setOnSuccess((data: IEmployeeResponse, employee: Employee) => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __('company.components.employees.change-email.form.on-success')
        })

        this.$store.dispatch(GlobalActions.closeDialog)
        this.meta.onSuccess && this.meta.onSuccess(employee)
      })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
