





import { Component, Prop, Vue } from 'vue-property-decorator'
import Employee from '@/shared/modules/employee/employee.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import EmployeeChangeEmailDialog from '@/company/components/employees/EmployeeChangeEmailDialog.vue'
import can from '@/shared/helpers/can.helper'
import permissions from '@/shared/helpers/permissions.helper'

@Component({
  methods: { can }
})
export default class EmployeeChangeEmail extends Vue {
  @Prop() entry!: Employee
  permissions = permissions

  openDialog() {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EmployeeChangeEmailDialog,
      maxWidth: 550,
      meta: {
        employee: this.entry,
        onSuccess: (employee: Employee) => this.$emit('setValue', employee.email)
      }
    })
  }
}
